import { Button, Col, Form, Modal, Row, Spin, Switch, Table, Tooltip, Typography, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { ReloadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import barchart from '../assets/images/Bar Chart.svg'
import barGraph1 from '../assets/images/Bar Chart 1.svg'
import barGraph2 from '../assets/images/Bar Chart 2.svg'
import { getDataCallContact, postCall, postCallPatch } from '../components/CommonApiCall';
import moment from 'moment'
import Search from 'antd/es/input/Search';
import AppUtils from '../Utils/AppUtils';
import { fileUrl } from '../constants/const';
import logoDel from '../assets/images/delete.png'

function DynamicSubscriptionDashboard() {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [data, setData] = useState([])
    const [refresLoading, setRefresLoading] = useState(false)
    const [userData, setUserData] = useState([]);
    const [type, setType] = useState('subscribed');
    const [searchText, setSearchText] = useState("");
    const [exportData, setExportData] = useState(false);
    const [delId, setDelId] = useState('')
    const [openDelModal, setOpenDelModal] = useState(false)
  
    
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        position: ['bottomCenter'],
        size: 'small'
    });

    const columns = [
        {
            title: 'Date',
            dataIndex: 'Date',
            key: 'Date',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => new Date(a.Date) - new Date(b.Date),
        },
        {
            title: 'Name',
            dataIndex: 'fullName',
            key: 'fullName',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.fullName.localeCompare(b.fullName)

        },
        {
            title: 'User Name',
            dataIndex: 'Users',
            key: 'Users',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.Users.localeCompare(b.Users)

        },
        {
            title: 'Email',
            dataIndex: 'Email',
            key: 'Reports',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.Email.localeCompare(b.Email)
        },
        {
            title: 'Phone Number',
            dataIndex: 'Phone_number',
            key: 'Phone_number'
        },
        {
            title: 'Subscription',
            dataIndex: 'subscription',
            key: 'subscription'
        },
        {
            title: 'Action',
            dataIndex: 'Action',
            key: 'Action',
            align: "center",
            render: (_, record) => {
                const onToggle = (checked) => {
                    onToggleChange(checked, record?._id)
                };
                return (
                    <>
                        <Switch className='me-2' defaultChecked={record.status == "1" ? true : false} onChange={onToggle} />
                        {/* <Button className='purpleButton px-4' onClick={() => handleView(record?._id)}>View</Button>
                  <Button className='purpleButton px-4' onClick={() => handleDelete(record?._id)}>Delete</Button>  */}
                        <Tooltip title="View User">
                            <>
                                <i class="bi bi-binoculars me-2" style={{ fontSize: "1.5rem", color: "#6240C9", cursor: "pointer" }} onClick={() => navigate("/user/"+record?._id)}></i>
                            </>
                        </Tooltip>

                        <Tooltip title="Delete">
                            <>
                                <i class="bi bi-trash" style={{ fontSize: "1.5rem", color: "#6240C9", cursor: "pointer" }} onClick={() => handleDelete(record?._id)}></i>
                            </>
                        </Tooltip>
                    </>)
            },
        },
    ];

    //*** to handle the Search Value ***//
    const handleSearch = (value) => {
        console.log('handle search called ', value)
        setSearchText(value);
        setPagination((prevPagination) => ({
            ...prevPagination,
            current: 1,
        }));
    };

    const handleType = (value) => {
        
        setType(value);
        setPagination((prevPagination) => ({
            ...prevPagination,
            current: 1,
        }));
    };

    const getData = async (is_export) => {
        setLoading(true)
        const successCb = (response) => {
            setLoading(false)
            setPagination((prevPagination) => ({
                ...prevPagination,
                total: response?.data?.data?.typedUsers?.totalResults ?? 10,
            }));

            let data = response?.data?.data ?? {}
            let users = response?.data?.data?.typedUsers?.results ?? []
            let file_path = response?.data?.data?.typedUsers?.export_path ?? false
            if (!is_export) {
                let usersData = users.map((item) => {

                    let phone = item?.phoneNumber ?? ""
                    let country = item?.countryCode ?? "";
                    let subscription = 'Not Subscribed'
                    let firstName = item?.firstName ?? ''
                    let lastName = item?.lastName ?? ''
                    let fullName = '--'
                    if (firstName != '' || lastName != '') {
                        fullName = firstName + " " + lastName
                    }
                    if (item.currentSub && item.currentSub && 'is_subscribed' in item.currentSub) {
                        // subscription = item?.planDetail?.plan_name ?? "--"
                        let monthlyPlanDetail = item?.monthlyPlanDetail ?? ""
                        let yearlyPlanDetail = item?.yearlyPlanDetail ?? ""
                        if (monthlyPlanDetail) {
                            subscription = monthlyPlanDetail?.plan_name ?? "--"
                            if (subscription != '--') {
                                subscription = subscription + " (Monthly)"
                            }
                        }
                        else if (yearlyPlanDetail) {
                            subscription = yearlyPlanDetail?.plan_name ?? "--"
                            if (subscription != '--') {
                                subscription = subscription + " (Yearly)"
                            }
                        } else {
                            subscription = '--'
                        }


                        if (item.currentSub.is_subscribed === 0) {
                            subscription = `Expired`
                        }
                        else if (item.currentSub.subscription_status == 'AUTO_RENEW_DISABLED') {
                            subscription = `Unsubscribed`
                        }


                    }
                    return ({
                        _id: item?._id,
                        key: item?._id,
                        Users: `${item?.userName ?? "--"}`,
                        fullName: `${item?.lastName ?? "--"}`,
                        Phone_number: (phone != "" && country != "") ? `+${country} ${phone}` : "--",
                        // Country: country,
                        Email: item?.email,
                        status: item?.status,
                        subscription,
                        Date: moment(item?.createdAt).format("MM/DD/YYYY")
                    })
                })
                setUserData(usersData);
            } else {

                console.log("file_path ==>", file_path)
                if (file_path)
                    handleFileUpload(file_path)

            }

            if (searchText == '' || !exportData)
                setData(data)
        }
        const failureCb = (response) => {
            console.log("failure ===> ", response)
            setLoading(false)
            setData([])
            setUserData([])
            setPagination({
                current: 1,
                total: 0,
                pageSize: 5,
            });
        }
        let data = {
            page: pagination?.current,
            limit: pagination?.pageSize,
            type
        }
        if (searchText != '') {
            data = { ...data, search: searchText }
        }
        if (is_export) {
            data = { ...data, is_export: "1" }

        }
        postCall(data, "admin/dynaminSubscription/dynamicDashboard", successCb, failureCb)
    }

    const handleFileUpload = (filePath) => {
        // let url = file
        if (filePath != "") {
            let url = `${fileUrl}${filePath}`
            console.log("url", url);
            const downloadLink = document.createElement('a');
            downloadLink.href = url;
            downloadLink.download = 'ImportSampleFile.xlsx';
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    }

    // *** Page change ******//
    const handlePageChange = (page, pageSize) => {
        setPagination((prevPagination) => ({
            ...prevPagination,
            current: page,
            pageSize: pageSize,
        }));
    };

    const onToggleChange = (checked, id) => {
        console.log(`switch to ${checked}`);
        let updatedStatus;
        if (checked == true) updatedStatus = "1";
        else updatedStatus = "0";
        let data = {
            status: updatedStatus,
        };
        console.log("updated title", data)
        let url = `admin/user/${id}`
        const successCb = (res) => {
            setLoading(false)
            getData()
            message.success("Updated Successfully")
        }
        const failureCb = (res) => {
            setLoading(false)
        }
        postCallPatch(data, url, successCb, failureCb)
    };


    const handleDelete = (id) => {
        setDelId(id)
        setOpenDelModal(true)
    }
    const onDeleteOk = () => {
        setLoading(true)

        let data = {
            status: "2"
        }
        let url = `admin/user/${delId}`
        const successCb = (res) => {
            setLoading(false)
            setOpenDelModal(false)
            message.success("User deleted successfully")
            getData()

        }
        const failureCb = (res) => {
            setLoading(false)
            setOpenDelModal(false)
        }
        postCallPatch(data, url, successCb, failureCb)
    }

    

    // useEffect(() => {
    //     getData();
    // }, [])


    useEffect(() => {
        getData();
    }, [pagination?.current, pagination?.pageSize, type, searchText, exportData])

    return (
        <div className='Maincard p-3'>
            <Form form={form} >
                <Spin spinning={loading}>
                    <Row>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <h5 className='fw-bold mt-2'>Dashboard</h5>
                        </Col>
                    </Row>
                    <Row>

                        <Col md={24} lg={24} xs={24} sm={24}>
                            <div className="mt-4 crads">
                                <Row gutter={[30, 30]} className='d-flex justify-content-around'>
                                    <Col xs={24} sm={24} md={12} lg={8} style={{ cursor: 'pointer' }} onClick={() => handleType('subscribed')}>
                                        <div className="Maincard p-3 ">
                                            <div className="d-flex justify-content-between ">
                                                <Typography className="total_paidText">Subscribed Users</Typography>
                                                <Typography className="d-flex gap-2" style={{ cursor: 'pointer' }} >
                                                    <ReloadOutlined spin={refresLoading} style={{ color: "#508FF4", fontSize: '1.2rem' }} />
                                                    <span style={{ color: "#508FF4", fontSize: '1rem' }}>Refresh</span>
                                                </Typography>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between mt-3">
                                                <div className="amountPrt">
                                                    <Typography className="dollerAmount ms-2">{data?.subscribed_users ?? 0}
                                                    </Typography>
                                                </div>
                                                <img src={barGraph1} alt="bargraph_img" />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={8} style={{ cursor: 'pointer' }} onClick={() => handleType('expired')}>
                                        <div className="Maincard p-3">
                                            <div className="d-flex justify-content-between">
                                                <Typography className="total_paidText">Expired Users  </Typography>
                                                <Typography className="d-flex gap-2" style={{ cursor: 'pointer' }} >
                                                    <ReloadOutlined spin={refresLoading} style={{ color: "#508FF4", fontSize: '1.2rem' }} />
                                                    <span style={{ color: "#508FF4", fontSize: '1rem' }}>Refresh</span>
                                                </Typography>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between mt-3">
                                                <div className="amountPrt">
                                                    <Typography className="dollerAmount ms-2">{data?.expired_users ?? 0}</Typography>
                                                </div>
                                                <img src={barGraph2} alt="bargraph_img" />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={8} style={{ cursor: 'pointer' }} onClick={() => handleType('renewed')}>
                                        <div className="Maincard p-3">
                                            <div className="d-flex justify-content-between">
                                                <Typography className="total_paidText">Renewed Users </Typography>
                                                <Typography className="d-flex gap-2" style={{ cursor: 'pointer' }} >
                                                    <ReloadOutlined spin={refresLoading} style={{ color: "#508FF4", fontSize: '1.2rem' }} />
                                                    <span style={{ color: "#508FF4", fontSize: '1rem' }}>Refresh</span>
                                                </Typography>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between mt-3">
                                                <div className="amountPrt">
                                                    <Typography className="dollerAmount ms-2">{data?.renewed_users ?? 0}</Typography>
                                                </div>
                                                <img src={barchart} alt="bargraph_img" />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row>

                        <Col md={24} lg={24} xs={24} sm={24}>
                            <div className="mt-4 crads">
                                <Row gutter={[30, 30]} className='d-flex justify-content-start'>
                                    <Col xs={24} sm={24} md={12} lg={8} style={{ cursor: 'pointer' }} onClick={() => handleType('upgraded')}>
                                        <div className="Maincard p-3">
                                            <div className="d-flex justify-content-between">
                                                <Typography className="total_paidText">Upgraded Users</Typography>
                                                <Typography className="d-flex gap-2" style={{ cursor: 'pointer' }} >
                                                    <ReloadOutlined spin={refresLoading} style={{ color: "#508FF4", fontSize: '1.2rem' }} />
                                                    <span style={{ color: "#508FF4", fontSize: '1rem' }}>Refresh</span>
                                                </Typography>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between mt-3">
                                                <div className="amountPrt">
                                                    <Typography className="dollerAmount ms-2">{data?.upgraded_users ?? 0}
                                                    </Typography>
                                                </div>
                                                <img src={barGraph1} alt="bargraph_img" />
                                            </div>
                                        </div>
                                    </Col>


                                    <Col xs={24} sm={24} md={12} lg={8} >
                                        <div className="Maincard p-3">
                                            <div className="d-flex justify-content-between">
                                                <Typography className="total_paidText">Gross Earnings</Typography>
                                                <Typography className="d-flex gap-2" style={{ cursor: 'pointer' }} >
                                                    <ReloadOutlined spin={refresLoading} style={{ color: "#508FF4", fontSize: '1.2rem' }} />
                                                    <span style={{ color: "#508FF4", fontSize: '1rem' }}>Refresh</span>
                                                </Typography>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between mt-3">
                                                <div className="amountPrt">
                                                    <Typography className="dollerAmount ms-2">${parseInt(data?.gross_earning ?? 0)}
                                                    </Typography>
                                                </div>
                                                <img src={barGraph1} alt="bargraph_img" />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} lg={8} style={{ cursor: 'pointer' }} onClick={() => handleType('unsubscribed')}>
                                        <div className="Maincard p-3">
                                            <div className="d-flex justify-content-between">
                                                <Typography className="total_paidText">Unsubscribed Users</Typography>
                                                <Typography className="d-flex gap-2" style={{ cursor: 'pointer' }} >
                                                    <ReloadOutlined spin={refresLoading} style={{ color: "#508FF4", fontSize: '1.2rem' }} />
                                                    <span style={{ color: "#508FF4", fontSize: '1rem' }}>Refresh</span>
                                                </Typography>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between mt-3">
                                                <div className="amountPrt">
                                                    <Typography className="dollerAmount ms-2">{parseInt(data?.unsubscribed_users ?? 0)}
                                                    </Typography>
                                                </div>
                                                <img src={barGraph1} alt="bargraph_img" />
                                            </div>
                                        </div>
                                    </Col>

                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row>

                        <Col md={24} lg={24} xs={24} sm={24}>
                            <div className="mt-4 crads">
                                <Row gutter={[30, 30]} className='d-flex justify-content-start'>
                                    <Col xs={24} sm={24} md={12} lg={8} style={{ cursor: 'pointer' }} onClick={() => handleType('not_subscribed')}>
                                        <div className="Maincard p-3">
                                            <div className="d-flex justify-content-between">
                                                <Typography className="total_paidText">Not Subscribed Users</Typography>
                                                <Typography className="d-flex gap-2" style={{ cursor: 'pointer' }} >
                                                    <ReloadOutlined spin={refresLoading} style={{ color: "#508FF4", fontSize: '1.2rem' }} />
                                                    <span style={{ color: "#508FF4", fontSize: '1rem' }}>Refresh</span>
                                                </Typography>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between mt-3">
                                                <div className="amountPrt">
                                                    <Typography className="dollerAmount ms-2">{data?.not_subscribed_users ?? 0}
                                                    </Typography>
                                                </div>
                                                <img src={barGraph1} alt="bargraph_img" />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row className='justify-content-between align-items-center'>
                        <Col xs={24} sm={24} md={18} lg={18}>
                            <h4 className='fw-bold mt-4 my-3'>{type === 'not_subscribed' ? 'Not Subscribed' : AppUtils.capitalizeFirstLetter(type)} Users</h4>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={6} className='d-flex flex-direction-row justify-content-center align-items-center'>
                            <Search
                                placeholder="Search by name.."
                                onSearch={handleSearch}
                                enterButton
                                allowClear
                                style={{ float: "right", }}
                            />
                            <Button className='purpleButton ms-2' onClick={() => getData(1)}>Export</Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={24} lg={24} xs={24} sm={24}>

                            <Table
                                // rowSelection={rowSelection}
                                dataSource={userData}
                                columns={columns}
                                scroll={{ x: "250px" }}
                                pagination={{
                                    ...pagination,
                                    onChange: handlePageChange,
                                }}

                            />
                        </Col>
                    </Row>







                </Spin>
            </Form>
            <Modal
                centered
                open={openDelModal}
                onCancel={() => setOpenDelModal(false)}
                footer={null}
                width={350}
            >
                <div className='text-center'>
                    <img src={logoDel} alt="delete_icon" style={{ width: "25%" }} />
                </div>
                <h5 className='text-center'>Delete User</h5>
                <p>Are you sure you want to Delete this User?</p>
                <div>
                    <Button type="primary" className='me-2 w-48' style={{ backgroundColor: "#6240C9" }} onClick={onDeleteOk}>Delete</Button>
                    <Button danger type="primary" className='w-48 ' onClick={() => { setOpenDelModal(false); setDelId('') }}>Cancel</Button>
                </div>
            </Modal>

        </div>
    )
}

export default DynamicSubscriptionDashboard