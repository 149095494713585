import { Avatar, Button, Col, Row, Typography } from "antd"
import { CaretLeftOutlined } from '@ant-design/icons';
import { fileUrl } from "../constants/const";
import moment from 'moment'
import VideoPlayer from "../components/VideoPlayer";
import { useEffect, useState } from "react";
import { getDataCallContact } from "../components/CommonApiCall";
import { useNavigate, useParams } from "react-router-dom";
import AppUtils from "../Utils/AppUtils";
const UserDetail = ({ }) => {

    const [viewData, setViewData] = useState(null);
    const [viewDetails, setViewDetails] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const { userId } = useParams();
    const watchTime = AppUtils.convertSeconds(viewData?.watchTime)
    useEffect(()=>{
     getUserDetail()
    },[])

    const getUserDetail = () => {
         // Extract the id from the URL
        console.log('userid ',userId)
        setLoading(true)
        const successCb = (response) => {
            setLoading(false)
            console.log("response single", response)
            setViewData(response?.data?.data);
            
        }
        const failureCb = (response) => {
            setLoading(false)
            
           
        }
        getDataCallContact(`admin/user/${userId}`, successCb, failureCb, 'test');
    }


    return (
        <div>
            {/* user details section */}
            <div>
                <Row className='mt-4 mb-1 justify-content-between'>
                    <h5 className='fw-bold'>User Detail</h5>
                </Row>
                <Row className='my-3 p-4 Maincard align-items-center' >
                    {/* {(viewData?.user?.profilePic) && */}
                    <Col xs={24} sm={24} md={12} lg={12}>
                        <Avatar size={150} shape="square" icon={<img src={`${fileUrl}${viewData?.profilePic}`} alt={(viewData?.firstName.charAt(0))?.toUpperCase()} type="square" style={{ borderRadius: "10px" }} />} />
                        <div className='m-1'><span className='fw-bold'>Name : </span><span>{`${viewData?.firstName}${viewData?.lastName}`}</span></div>
                        <div className='m-1'><span className='fw-bold'>Email : </span><span>{`${viewData?.email}`}</span></div>
                        {viewData?.countryCode && viewData?.phoneNumber && <div className='m-1'><span className='fw-bold'>Phone No : </span><span>{`+${viewData?.countryCode} ${viewData?.phoneNumber}`}</span></div>}
                    </Col>

                    <Col xs={24} sm={12} md={11} lg={11} className='d-flex justify-content-evenly flex-column' >
                        <div className='Maincard'>
                            {/* <Row className='p-2 pb-0 justify-content-between'>
                    <h6 className='fw-bold p-1 my-0'>Pro Subscription <span className='p-1' style={{ backgroundColor: "#F0EBFB", color: "#454CC9", fontWeight: "400", borderRadius: "5px" }}>one account</span></h6>
                    <span className='fw-bold fs-large my-0' style={{ color: "#454CC9" }}>$9.99</span>
                  </Row>
                  <Row className='p-2 py-0 justify-content-between'>
                    <p className='p-1'>Billed each month starts after trail ends</p>
                    <p className='fw-bold'>Per Month</p>
                  </Row> */}
                            <Row className='d-flex justify-content-between p-2 align-items-center'>
                                <h6 className='fw-bold p-1 m-0'>Status</h6>
                                <span className='p-2 m-0'>{viewData?.status == "1" ? "Active" : "Inactive"}</span>
                                {/* <Switch className='' defaultChecked={viewData?.user?.status == "1" ? true : false} size='small' onChange={onToggleViewDetails} /> */}
                            </Row>
                            <Row className='p-2 justify-content-between align-items-center'>
                                <h6 className='fw-bold'>Streaming Statistics</h6>
                                <Button onClick={() => navigate('/streaming_analysis_user', { state: { userId: viewData?._id } })}>View</Button>
                            </Row>

                        </div>
                        <div className='Maincard my-1'>
                            <Row className='justify-content-between' style={{ padding: '5px 10px 0px 10px ' }}>
                                <Typography className='' style={{ fontSize: "1.2rem" }}>Watch Time</Typography>
                                <div className="my-0">
                                    <Typography className="mb-0 pb-0" style={{ fontSize: "1rem", }}>{watchTime.hours}:{watchTime.minutes}:{watchTime.seconds} Hours
                                    </Typography>
                                </div>
                            </Row>
                            <Row className='justify-content-between' style={{ padding: '5px 10px 0px 10px ' }}>
                                <Typography className='' style={{ fontSize: "1.2rem" }}>Category Selected</Typography>
                                <div className="my-0">
                                    <Typography className="mb-0 pb-0" style={{ fontSize: "1rem" }}>{viewData?.categorySelected?.join(",")}
                                    </Typography>
                                </div>
                            </Row>
                            <Row className='justify-content-between' style={{ padding: '5px 10px 0px 10px ' }}>
                                <Typography className='' style={{ fontSize: "1.2rem" }}>D.O.B</Typography>
                                <div className="my-0">
                                    <Typography className="mb-0 pb-0" style={{ fontSize: "1rem" }}>{moment(viewData?.dob).format("MM/DD/YYYY")}
                                    </Typography>
                                </div>
                            </Row>
                            <Row className='justify-content-between' style={{ padding: '5px 10px 0px 10px ' }}>
                                <Typography className='' style={{ fontSize: "1.2rem" }}>Address</Typography>
                                <div className="my-0">
                                    <Typography className="mb-0 pb-0" style={{ fontSize: "1rem" }}>{viewData?.address}
                                    </Typography>
                                </div>
                            </Row>
                            <Row className='p-3 justify-content-between'>


                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
            {/* reports section */}
            <div className='p-1'>
                {viewData?.videos?.length > 0 && <Row className='mt-4 mb-1'><h5 className='fw-bold'>Reports</h5>
                </Row>}
                <Row>
                    {viewData?.videos?.length > 0 && viewData?.videos?.map((video) => {
                        return (
                            <>
                                {video?.isLive == false &&
                                    <Col xs={24} sm={24} md={11} lg={11} className='p-3 m-1 Maincard'>
                                        <Row className='p-2'>
                                            {console.log("url", video?.video)}
                                            <Col xs={24} sm={24} md={10} lg={10}>
                                                {(video?.video || video?.thumbnail) &&
                                                    <VideoPlayer videoUrl={video?.video} thumbnailLink={`${fileUrl}${video?.thumbnail}`} streamType={video?.streamType} />
                                                }
                                            </Col>
                                            <Col xs={24} sm={24} md={12} lg={12}>
                                                <div className='p-1 pb-0'><span className='fw-bold'>Title: </span>{video?.title}</div>
                                                <div className='p-1 pb-0' style={{ wordBreak: "break-word" }}><span className='fw-bold'>Description: </span>{video?.desc}</div>
                                                <div className='p-1 pb-0'><span className='fw-bold'>Category: </span>{video?.channelCategory}</div>
                                                <div className='p-1 pb-0'><span className='fw-bold'>Type: </span>{video?.broadcastType}</div>
                                                {video?.equips?.length > 0 && <div className='d-flex p-1 pb-0'><span className='fw-bold'>Equips: </span><span>{video?.equips?.map((equip) => <div> {equip} </div>)}</span></div>}
                                                {video?.tags?.length > 0 && <div className='d-flex p-1 pb-0'><span className='fw-bold'>Tags: </span><span>{video?.tags?.map((tag) => <div> {tag} </div>)}</span></div>}
                                            </Col>
                                        </Row>
                                    </Col>
                                }
                            </>
                        )
                    })}
                </Row>
            </div>
        </div>
    )
}

export default UserDetail
