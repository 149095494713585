import { Button, Col, Row, Table, Modal, message, Spin, Typography, Switch, Card, Avatar, Tooltip } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import barchart from '../assets/images/Bar Chart.svg'
import barchart1 from '../assets/images/Bar Chart 1.svg'
import barGraph1 from '../assets/images/Bar Chart 1.svg'
import barchart2 from '../assets/images/Bar Chart 2.svg'
import barGraph2 from '../assets/images/Bar Chart 2.svg'
import refresh from '../assets/images/md-refresh.svg'
import refresh1 from '../assets/images/refresh.svg'
import arrow_up from '../assets/images/Arrow Up.svg'
import arrow_down from '../assets/images/ArrowDown.svg'
import map from '../assets/images/map (1).svg'
import usa from '../assets/images/Usa@2x.png'
import StackedAreaChart from '../components/StackAreaChart'
import { ReloadOutlined, ArrowUpOutlined, ArrowDownOutlined, CaretLeftOutlined } from '@ant-design/icons';
import logoDel from '../assets/images/delete.png'
import { getAppData, getDataCallContact, getDataDashboard, postCall, postCallPatch } from '../components/CommonApiCall'
import Search from 'antd/es/input/Search';
import { fileUrl,appEnv } from '../constants/const'
import VideoPlayer from '../components/VideoPlayer'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import AppUtils from '../Utils/AppUtils'




function Overview() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [userData, setUserData] = useState([]);
  const [refresLoading, setRefresLoading] = useState(false)
  const [openUserDetails, setOpenUserDetails] = useState(false);
  const [openDelModal, setOpenDelModal] = useState(false)
  const [count, setCount] = useState({})
  const [delId, setDelId] = useState('')
  const [loading, setLoading] = useState(false);
  const [blockId, setBlockId] = useState();
  const [viewModal, setViewModal] = useState(false);
  const [viewData, setViewData] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [viewDetails, setViewDetails] = useState(false);
  const [openBlockModal, setOpenBlockModal] = useState(false);
  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    position: ['bottomCenter'],
    size: 'small'
  });

  useEffect(() => {
    getDataCount()
  }, [])

  //  to fetch the data
  const getDataCount = async () => {
    console.log("fileUrl ",fileUrl)
    console.log("appEnv ",appEnv)
    setLoading(true)
    const successCb = (response) => {
      setLoading(false)
      setCount(response?.data)

    }
    const failureCb = (response) => {
      setLoading(false)
    }
    getDataCallContact("/admin/dashboard/count", successCb, failureCb);
  }
  useEffect(() => {
    getData();
  }, [pagination?.current, pagination?.pageSize, searchText])

  const handleFileUpload = (filePath) => {
    // let url = file
    if (filePath != "") {
      let url = `${fileUrl}${filePath}`
      console.log("url", url);
      const downloadLink = document.createElement('a');
      downloadLink.href = url;
      downloadLink.download = 'ImportSampleFile.xlsx';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }

  //** Called to fetch the users data record **//
  const getData = async (is_export) => {
    setLoading(true)
    const successCb = (response) => {
      setLoading(false)
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: response?.data?.totalResults,
      }));
      if (is_export) {
        let file_path = response?.data?.export_path ?? ""
        if (file_path)
          handleFileUpload(file_path)
        return
      }
      let data = response?.data?.results.map((item) => {

        let phone = item?.phoneNumber ?? ""
        let country = item?.countryCode ?? "";
        let subscription = 'Not Subscribed'
        if (item.currentSub && item.currentSub && 'is_subscribed' in item.currentSub) {
          // subscription = item?.planDetail?.plan_name ?? "--"
          let monthlyPlanDetail = item?.monthlyPlanDetail??""
          let yearlyPlanDetail = item?.yearlyPlanDetail??""
          if(monthlyPlanDetail) {
            subscription = monthlyPlanDetail?.plan_name ?? "--"
            if(subscription!='--') {
              subscription = subscription+" (Monthly)"
            }
          }
          else if(yearlyPlanDetail) {
            subscription = yearlyPlanDetail?.plan_name ?? "--"
            if(subscription!='--') {
              subscription = subscription+" (Yearly)"
            }
          } else{
            subscription = '--'
          }
          
          
          if(item.currentSub.is_subscribed === 0) {
             subscription = `Expired`
          }
          else if(item.currentSub.subscription_status=='AUTO_RENEW_DISABLED') {
            subscription = `Unsubscribed`
          } 
          
          
        }
        return ({
          _id: item?._id,
          key: item?._id,
          Users: `${item?.userName ?? "--"}`,
          fullName: `${item?.fullName ?? "--"}`,
          Phone_number: (phone != "" && country != "") ? `+${country} ${phone}` : "--",
          // Country: country,
          Email: item?.email,
          status: item?.status,
          subscription,
          Date: moment(item?.createdAt).format("MM/DD/YYYY")
        })
      })
      setUserData(data);
    }
    const failureCb = (response) => {
      setLoading(false)
      setUserData('')
      setPagination({
        current: 1,
        total: 0,
        pageSize: 5,
      });
    }
    getDataDashboard(pagination?.current, pagination?.pageSize, "/admin/dashboard", searchText, is_export, successCb, failureCb);
  }

  // handler for selected rows // 
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: 'odd',
        text: 'Select Odd Row',
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: 'even',
        text: 'Select Even Row',
        onSelect: (changeableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changeableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  //*** to handle the Search Value ***//
  const handleSearch = (value) => {
    setSearchText(value);
    setPagination((prevPagination) => ({
      ...prevPagination,
      current: 1,
    }));
  };

  const handleView = (id) => {
    setLoading(true)
    const successCb = (response) => {
      setLoading(false)
      console.log("response single", response)
      setViewData(response?.data?.data);
      setViewDetails(true)
    }
    const failureCb = (response) => {
      setLoading(false)
      setUserData('')
      setPagination({
        current: 1,
        total: 0,
        pageSize: 5,
      });
    }
    getDataCallContact(`admin/user/${id}`, successCb, failureCb, 'test');
  }

  const handleBlock = (id) => {
    setBlockId(id)
    setOpenBlockModal(true)
  }
  const onBlockOk = () => {
    setLoading(true)
    let data = {
      _id: blockId,
    }
    return false;
    let url = '/settings/del_faq'
    const successCb = (res) => {
      setLoading(false)
      getData()
      message.success("Question Deleted Successfully")
      // form.resetFields()
      setDelId(null)
      setOpenDelModal(false)
    }
    const failureCb = (res) => {
      setLoading(false)
    }
    postCall(data, url, successCb, failureCb)
  }

  const handleDelete = (id) => {
    setDelId(id)
    setOpenDelModal(true)
  }
  const onDeleteOk = () => {
    setLoading(true)

    let data = {
      status: "2"
    }
    let url = `admin/user/${delId}`
    const successCb = (res) => {
      setLoading(false)
      setOpenDelModal(false)
      message.success("User deleted successfully")
      getData()
      getDataCount()
    }
    const failureCb = (res) => {
      setLoading(false)
      setOpenDelModal(false)
    }
    postCallPatch(data, url, successCb, failureCb)
  }

  // *** Page change ******//
  const handlePageChange = (page, pageSize) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current: page,
      pageSize: pageSize,
    }));
  };

  const onToggleChange = (checked, id) => {
    console.log(`switch to ${checked}`);
    let updatedStatus;
    if (checked == true) updatedStatus = "1";
    else updatedStatus = "0";
    let data = {
      status: updatedStatus,
    };
    console.log("updated title", data)
    let url = `admin/user/${id}`
    const successCb = (res) => {
      setLoading(false)
      getData()
      message.success("Updated Successfully")
    }
    const failureCb = (res) => {
      setLoading(false)
    }
    postCallPatch(data, url, successCb, failureCb)
  };

  // columns for the table //
  const columns = [
    {
      title: 'Date',
      dataIndex: 'Date',
      key: 'Date',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => new Date(a.Date) - new Date(b.Date),
    },
    {
      title: 'Name',
      dataIndex: 'fullName',
      key: 'fullName',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.fullName.localeCompare(b.fullName)

    },
    {
      title: 'User Name',
      dataIndex: 'Users',
      key: 'Users',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.Users.localeCompare(b.Users)

    },
    {
      title: 'Email',
      dataIndex: 'Email',
      key: 'Reports',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.Email.localeCompare(b.Email)
    },
    {
      title: 'Phone Number',
      dataIndex: 'Phone_number',
      key: 'Phone_number'
    },
    {
      title: 'Subscription',
      dataIndex: 'subscription',
      key: 'subscription'
    },

    {
      title: 'Action',
      dataIndex: 'Action',
      key: 'Action',
      align: "center",
      render: (_, record) => {
        const onToggle = (checked) => {
          onToggleChange(checked, record?._id)
        };
        return (
          <>
            <Switch className='me-2' defaultChecked={record.status == "1" ? true : false} onChange={onToggle} />
            {/* <Button className='purpleButton px-4' onClick={() => handleView(record?._id)}>View</Button>
            <Button className='purpleButton px-4' onClick={() => handleDelete(record?._id)}>Delete</Button>  */}
            <Tooltip title="View User">
              <>
                <i class="bi bi-binoculars me-2" style={{ fontSize: "1.5rem", color: "#6240C9", cursor: "pointer" }} onClick={() => handleView(record?._id)}></i>
              </>
            </Tooltip>

            <Tooltip title="Delete">
              <>
                <i class="bi bi-trash" style={{ fontSize: "1.5rem", color: "#6240C9", cursor: "pointer" }} onClick={() => handleDelete(record?._id)}></i>
              </>
            </Tooltip>
          </>)
      },
    },
  ];

  const onBackClick = () => {
    setViewDetails(false)
    setViewData(null);
    getData();
  }

  const onToggleViewDetails = (checked) => {
    onToggleChange(checked, viewData?._id)
  };


 
  const watchTime = AppUtils.convertSeconds(viewData?.watchTime)
  return (
    <>
      {/* overview section */}
      {!viewDetails &&
        <div className='Maincard p-4'>
          {loading && <Spin size="large" className='loader' />}
          <h4 className='fw-bold mb-2'>Overview</h4>
          <div className="mt-4 crads">
            <Row gutter={[30, 30]} className='d-flex justify-content-around'>
              <Col xs={24} sm={24} md={12} lg={7}>
                <div className="Maincard p-3">
                  <div className="d-flex justify-content-between">
                    <Typography className="total_paidText">Total Non Hosts</Typography>
                    <Typography className="d-flex gap-2" style={{ cursor: 'pointer' }} onClick={() => getData()}>
                      <ReloadOutlined spin={refresLoading} style={{ color: "#508FF4", fontSize: '1.2rem' }} />
                      <span style={{ color: "#508FF4", fontSize: '1rem' }}>Refresh</span>
                    </Typography>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <div className="amountPrt">
                      <Typography className="dollerAmount ms-2">{count?.users ?? 0}
                      </Typography>
                    </div>
                    <img src={barGraph1} alt="bargraph_img" />
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={7}>
                <div className="Maincard p-3">
                  <div className="d-flex justify-content-between">
                    <Typography className="total_paidText">Total Hosts  </Typography>
                    <Typography className="d-flex gap-2" style={{ cursor: 'pointer' }} onClick={() => getData()}>
                      <ReloadOutlined spin={refresLoading} style={{ color: "#508FF4", fontSize: '1.2rem' }} />
                      <span style={{ color: "#508FF4", fontSize: '1rem' }}>Refresh</span>
                    </Typography>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <div className="amountPrt">
                      <Typography className="dollerAmount ms-2">{count?.hosts}</Typography>
                    </div>
                    <img src={barGraph2} alt="bargraph_img" />
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={7}>
                <div className="Maincard p-3">
                  <div className="d-flex justify-content-between">
                    <Typography className="total_paidText">Total Coaches </Typography>
                    <Typography className="d-flex gap-2" style={{ cursor: 'pointer' }} onClick={() => getData()}>
                      <ReloadOutlined spin={refresLoading} style={{ color: "#508FF4", fontSize: '1.2rem' }} />
                      <span style={{ color: "#508FF4", fontSize: '1rem' }}>Refresh</span>
                    </Typography>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <div className="amountPrt">
                      <Typography className="dollerAmount ms-2">{count?.coaches}</Typography>
                    </div>
                    <img src={barchart} alt="bargraph_img" />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      }
      {/* Active Users Section */}
      {/* <div>
        <h4 className='fw-bold my-2'>Active Users</h4>
        <Row>
          <Col xs={24} sm={24} md={16} lg={16} className='p-3'>
            <img src={map} alt="" className='w-100' />
          </Col>
          <Col xs={24} sm={24} md={8} lg={8}>
            <div className='fw-bold fs-5'>100k</div>
            <div className='mb-2' style={{ fontSize: "15px", color: "grey", fontWeight: "600" }}>Active Users</div>
            <div className='d-flex justify-content-between pe-2 mb-3'>
              <span><img src={usa} alt="" className='w-25 me-2' />USA</span>
              <span className='fw-bold'>39K</span>
            </div>
            <div className='d-flex justify-content-between pe-2 mb-3'>
              <span><img src={usa} alt="" className='w-25 me-2' />Spain</span>
              <span className='fw-bold'>19K</span>
            </div>
            <div className='d-flex justify-content-between pe-2 mb-3'>
              <span><img src={usa} alt="" className='w-25 me-2' />Russia</span>
              <span className='fw-bold'>39K</span>
            </div>
            <div className='d-flex justify-content-between pe-2 mb-3'>
              <span><img src={usa} alt="" className='w-25 me-2' />Brazil</span>
              <span className='fw-bold'>39K</span>
            </div>
          </Col>
        </Row>
      </div> */}
      {/* Earning statics section */}
      {/* <div>
        <h4 className='fw-bold mt-4 my-3'>Earning Statistics</h4>
        <div className='p-2'><StackedAreaChart /></div>
      </div> */}

      {/* user managers section */}
      {!viewDetails &&
        <div>
          <Row className='justify-content-between align-items-center'>
            <Col xs={24} sm={24} md={18} lg={18}>
              <h4 className='fw-bold mt-4 my-3'>Users Manager</h4>
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} className='d-flex flex-direction-row justify-content-center align-items-center'>
              <Search
                placeholder="Search by name.."
                onSearch={handleSearch}
                enterButton
                allowClear
                style={{ float: "right", }}
              />
              <Button className='purpleButton ms-2' onClick={() => getData(1)}>Export</Button>
            </Col>
          </Row>
          <Table
            // rowSelection={rowSelection}
            dataSource={userData}
            columns={columns}
            scroll={{ x: "250px" }}
            pagination={{
              ...pagination,
              onChange: handlePageChange,
            }} />
        </div>
      }
      {
        viewDetails &&
        <div>
          {/* user details section */}
          <div>
            <Row className='mt-4 mb-1 justify-content-between'>
              <h5 className='fw-bold'>User Detail</h5>
              <Button className='back d-flex jusitify-content-center align-items-center' onClick={() => onBackClick()}><CaretLeftOutlined />Back</Button>
            </Row>
            <Row className='my-3 p-4 Maincard align-items-center' >
              {/* {(viewData?.user?.profilePic) && */}
              <Col xs={24} sm={24} md={12} lg={12}>
                <Avatar size={150} shape="square" icon={<img src={`${fileUrl}${viewData?.profilePic}`} alt={(viewData?.firstName.charAt(0))?.toUpperCase()} type="square" style={{ borderRadius: "10px" }} />} />
                <div className='m-1'><span className='fw-bold'>Name : </span><span>{`${viewData?.firstName}${viewData?.lastName}`}</span></div>
                <div className='m-1'><span className='fw-bold'>Email : </span><span>{`${viewData?.email}`}</span></div>
                {viewData?.countryCode && viewData?.phoneNumber && <div className='m-1'><span className='fw-bold'>Phone No : </span><span>{`+${viewData?.countryCode} ${viewData?.phoneNumber}`}</span></div>}
              </Col>

              <Col xs={24} sm={12} md={11} lg={11} className='d-flex justify-content-evenly flex-column' >
                <div className='Maincard'>
                  {/* <Row className='p-2 pb-0 justify-content-between'>
                    <h6 className='fw-bold p-1 my-0'>Pro Subscription <span className='p-1' style={{ backgroundColor: "#F0EBFB", color: "#454CC9", fontWeight: "400", borderRadius: "5px" }}>one account</span></h6>
                    <span className='fw-bold fs-large my-0' style={{ color: "#454CC9" }}>$9.99</span>
                  </Row>
                  <Row className='p-2 py-0 justify-content-between'>
                    <p className='p-1'>Billed each month starts after trail ends</p>
                    <p className='fw-bold'>Per Month</p>
                  </Row> */}
                  <Row className='d-flex justify-content-between p-2 align-items-center'>
                    <h6 className='fw-bold p-1 m-0'>Status</h6>
                    <span className='p-2 m-0'>{viewData?.status == "1" ? "Active" : "Inactive"}</span>
                    {/* <Switch className='' defaultChecked={viewData?.user?.status == "1" ? true : false} size='small' onChange={onToggleViewDetails} /> */}
                  </Row>
                  <Row className='p-2 justify-content-between align-items-center'>
                    <h6 className='fw-bold'>Streaming Statistics</h6>
                    <Button onClick={() => navigate('/streaming_analysis_user', { state: { userId: viewData?._id } })}>View</Button>
                  </Row>

                </div>
                <div className='Maincard my-1'>
                  <Row className='justify-content-between' style={{ padding: '5px 10px 0px 10px ' }}>
                    <Typography className='' style={{ fontSize: "1.2rem" }}>Watch Time</Typography>
                    <div className="my-0">
                      <Typography className="mb-0 pb-0" style={{ fontSize: "1rem", }}>{watchTime.hours}:{watchTime.minutes}:{watchTime.seconds} Hours
                      </Typography>
                    </div>
                  </Row>
                  <Row className='justify-content-between' style={{ padding: '5px 10px 0px 10px ' }}>
                    <Typography className='' style={{ fontSize: "1.2rem" }}>Category Selected</Typography>
                    <div className="my-0">
                      <Typography className="mb-0 pb-0" style={{ fontSize: "1rem" }}>{viewData?.categorySelected?.join(",")}
                      </Typography>
                    </div>
                  </Row>
                  <Row className='justify-content-between' style={{ padding: '5px 10px 0px 10px ' }}>
                    <Typography className='' style={{ fontSize: "1.2rem" }}>D.O.B</Typography>
                    <div className="my-0">
                      <Typography className="mb-0 pb-0" style={{ fontSize: "1rem" }}>{moment(viewData?.dob).format("MM/DD/YYYY")}
                      </Typography>
                    </div>
                  </Row>
                  <Row className='justify-content-between' style={{ padding: '5px 10px 0px 10px ' }}>
                    <Typography className='' style={{ fontSize: "1.2rem" }}>Address</Typography>
                    <div className="my-0">
                      <Typography className="mb-0 pb-0" style={{ fontSize: "1rem" }}>{viewData?.address}
                      </Typography>
                    </div>
                  </Row>
                  <Row className='p-3 justify-content-between'>


                  </Row>
                </div>
              </Col>
            </Row>
          </div>
          {/* reports section */}
          <div className='p-1'>
            {viewData?.videos?.length > 0 && <Row className='mt-4 mb-1'><h5 className='fw-bold'>Reports</h5>
            </Row>}
            <Row>
              {viewData?.videos?.length > 0 && viewData?.videos?.map((video) => {
                return (
                  <>
                    {video?.isLive == false &&
                      <Col xs={24} sm={24} md={11} lg={11} className='p-3 m-1 Maincard'>
                        <Row className='p-2'>
                          {console.log("url", video?.video)}
                          <Col xs={24} sm={24} md={10} lg={10}>
                            {(video?.video || video?.thumbnail) &&
                              <VideoPlayer videoUrl={video?.video} thumbnailLink={`${fileUrl}${video?.thumbnail}`} streamType={video?.streamType} />
                            }
                          </Col>
                          <Col xs={24} sm={24} md={12} lg={12}>
                            <div className='p-1 pb-0'><span className='fw-bold'>Title: </span>{video?.title}</div>
                            <div className='p-1 pb-0' style={{ wordBreak: "break-word" }}><span className='fw-bold'>Description: </span>{video?.desc}</div>
                            <div className='p-1 pb-0'><span className='fw-bold'>Category: </span>{video?.channelCategory}</div>
                            <div className='p-1 pb-0'><span className='fw-bold'>Type: </span>{video?.broadcastType}</div>
                            {video?.equips?.length > 0 && <div className='d-flex p-1 pb-0'><span className='fw-bold'>Equips: </span><span>{video?.equips?.map((equip) => <div> {equip} </div>)}</span></div>}
                            {video?.tags?.length > 0 && <div className='d-flex p-1 pb-0'><span className='fw-bold'>Tags: </span><span>{video?.tags?.map((tag) => <div> {tag} </div>)}</span></div>}
                          </Col>
                        </Row>
                      </Col>
                    }
                  </>
                )
              })}
            </Row>
          </div>
        </div>
      }

      <Modal
        centered
        open={openDelModal}
        onCancel={() => setOpenDelModal(false)}
        footer={null}
        width={350}
      >
        <div className='text-center'>
          <img src={logoDel} alt="delete_icon" style={{ width: "25%" }} />
        </div>
        <h5 className='text-center'>Delete User</h5>
        <p>Are you sure you want to Delete this User?</p>
        <div>
          <Button type="primary" className='me-2 w-48' style={{ backgroundColor: "#6240C9" }} onClick={onDeleteOk}>Delete</Button>
          <Button danger type="primary" className='w-48 ' onClick={() => { setOpenDelModal(false); setDelId('') }}>Cancel</Button>
        </div>
      </Modal>

      <Modal
        centered
        open={openBlockModal}
        onCancel={() => { setOpenBlockModal(false); setBlockId(null) }}
        footer={null}
        width={350}
      >
        {/* <div className='text-center'>
          <img src={logoDel} alt="delete_icon" style={{ width: "25%" }} />
        </div> */}
        <h5 className='text-center'>Block User</h5>
        <p>Are you sure you want to Block this User?</p>
        <div>
          <Button type="primary" className='me-2 w-48' style={{ backgroundColor: "#6240C9" }} onClick={onBlockOk}>Block</Button>
          <Button danger type="primary" className='w-48 ' onClick={() => { setOpenBlockModal(false); setBlockId('') }}>Cancel</Button>
        </div>
      </Modal>
      <Modal
        centered
        open={viewModal}
        key={viewData}
        onCancel={() => { setViewModal(false); setViewData('') }}
        footer={null}
        width={400}
      >
        <Card
          // title={`${viewData?.firstName}${viewData?.lastName}`}
          bordered={false}
          cover={<img className="mt-1" alt="example" src={`${fileUrl}${viewData?.profilePic}`} style={{ width: "200px", height: "150px" }} />}
          style={{
            width: 350,
          }}
        >
          <div className='p-1'><span className='fw-bold'>Name : </span><span>{`${viewData?.firstName}${viewData?.lastName}`}</span></div>
          <div className='p-1'><span className='fw-bold'>Email : </span><span>{viewData?.email}</span></div>
          <div className='p-1'><span className='fw-bold'>Phone No : </span><span>+{viewData?.countryCode} {viewData?.phoneNumber}</span></div>
          {viewData?.address && <div className='p-1'><span className='fw-bold'>Address : </span><span>{viewData?.address}</span></div>}
          {viewData?.categorySelected?.[0] && <div className='d-flex p-1'><span className='fw-bold'>Category Selected : </span><span>{viewData?.categorySelected?.map((cat) => <div> {cat} </div>)}</span></div>}
        </Card>
      </Modal>

    </>
  )
}

// Fulll screen video player for user reporting detail part
// const VideoPlayer = ({ videoUrl, thumbnailLink, streamType }) => {
//   const [showPlayer, setShowPlayer] = useState(false);
//   const elementRef = useRef(null);
//   console.log("videoplayer", videoUrl, thumbnailLink)
//   const requestFullscreen = () => {
//     setShowPlayer(true)
//   };
//   let videoLink;
//   if (videoUrl) {
//     if (streamType == "pre") videoLink = "https://dst2itxekhxmi.cloudfront.net/" + videoUrl;
//     else videoLink = "https://dst2itxekhxmi.cloudfront.net/streams/" + videoUrl;
//     console.log("videoLink", videoLink)
//   }
//   useEffect(() => {
//     if (showPlayer) {
//       if (elementRef.current) {
//         if (elementRef.current.requestFullscreen) {
//           elementRef.current.requestFullscreen();
//         } else if (elementRef.current.mozRequestFullScreen) { // Firefox
//           elementRef.current.mozRequestFullScreen();
//         } else if (elementRef.current.webkitRequestFullscreen) { // Chrome, Safari, and Opera
//           elementRef.current.webkitRequestFullscreen();
//         } else if (elementRef.current.msRequestFullscreen) { // IE/Edge
//           elementRef.current.msRequestFullscreen();
//         }
//         // setShowPlayer(true)
//       }
//     }
//   }, [showPlayer])
//   return (
//     <div className="video-container d-flex justify-content-center flex-column align-items-center">
//       {showPlayer ?
//         <div className=''>
//           {videoLink && <video controls autoPlay ref={elementRef} style={{ width: "10rem", height: "8rem" }}>
//             <source src={videoLink} type="video/mp4" />
//             Your browser does not support the video tag.
//           </video>}
//         </div>
//         :
//         <>
//           <img src={thumbnailLink} alt="avatar" type="square" style={{ width: "10rem", height: "8rem", borderRadius: "10px" }} />
//           {videoLink && <Button className="mt-2" onClick={() => requestFullscreen()}>Play Video</Button>}
//         </>
//       }
//     </div>
//   );
// };

export default Overview