import { Button, Col, Form, Input, Row, Switch, Select, Collapse, Spin, message, Tooltip, Table, Divider, Modal, Checkbox } from 'antd'
import React, { useEffect, useState } from 'react'
import { CheckOutlined, CloseOutlined,CaretLeftOutlined } from '@ant-design/icons';
import AppUtils from '../Utils/AppUtils';
import { getDataCall, postCall } from '../components/CommonApiCall';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LeftOutlined } from '@ant-design/icons';

function DynamicSubscriptionEdit() {
    const [form] = Form.useForm();
    const [updateFields, setUpdateFields] = useState([]);
    const [loading, setLoading] = useState(false)
    const [updateBtnLoading, setUpdateBtnLoading] = useState(false)

    const [monthlyForm] = Form.useForm();
    const [yearlyForm] = Form.useForm();
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);  // This will navigate back to the previous page in history
    };

    const [duration, setDuration] = useState([
        {
            label: "Monthly",
            value: 'monthly'
        },
        {
            label: "Yearly",
            value: 'yearly'
        },
    ])
    const [gracePeriod, setGracePeriod] = useState([
        {
            label: "Month",
            value: 'month'
        },
        {
            label: "Days",
            value: 'days'
        },
    ])
    const [selectedDuration, setSelectedDuration] = useState([])
    const [coupons_monthly, setCouponsMonthly] = useState([])
    const [coupons_yearly, setCouponsYearly] = useState([])
    const [monthlyModal, setMonthlyModal] = useState(false)
    const [yearlyModal, setYearlyModal] = useState(false)
    const [updateMonthly, setUpdateMonthly] = useState(false)
    const [updateYearly, setUpdateYearly] = useState(false)
    const [monthlyPlanId, setMonthlyPlanId] = useState("")
    const [yearlyPlanId, setYearlyPlanId] = useState("")
    const [couponId, setCouponId] = useState("")
    const [featureId, setFeatureId] = useState("")
    const [featureDesc, setFeatureDesc] = useState("")
    const [subscriptionFeatures, setSubscriptionFeatures] = useState([])

    const columns_monthly = [
        {
            title: 'S.No',
            dataIndex: 'sno',
            key: 'sno',
            render: (_, record, index) => {
                return (
                    <label>{index + 1}</label>
                )
            }
        },
        {
            title: 'Coupon Code',
            dataIndex: 'coupon_code',
            key: 'coupon_code',
        },
        {
            title: 'Coupon Description',
            dataIndex: 'coupon_desc',
            key: 'coupon_desc',
        },
        {
            title: 'Action',
            dataIndex: 'Action',
            key: 'Action',
            align: "center",
            render: (_, record) => {

                return (
                    <>

                        <Tooltip title="Edit Coupon">
                            <>
                                <i class="bi bi-pencil-square me-2" style={{ fontSize: "1.5rem", color: "#6240C9", cursor: "pointer" }} onClick={() => onEditMonthly(record)}></i>
                            </>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <>
                                <i class="bi bi-trash" style={{ fontSize: "1.5rem", color: "#6240C9", cursor: "pointer" }} onClick={() => onDeleteMonthly(record?._id)}></i>
                            </>
                        </Tooltip>
                    </>)
            },
        }
    ];

    const columns_yearly = [
        {
            title: 'S.No',
            dataIndex: 'sno',
            key: 'sno',
            render: (_, record, index) => {
                return (
                    <label>{index + 1}</label>
                )
            }
        },
        {
            title: 'Coupon Code',
            dataIndex: 'coupon_code',
            key: 'coupon_code',
        },
        {
            title: 'Coupon Description',
            dataIndex: 'coupon_desc',
            key: 'coupon_desc',
        },
        {
            title: 'Action',
            dataIndex: 'Action',
            key: 'Action',
            align: "center",
            render: (_, record) => {

                return (
                    <>

                        <Tooltip title="Edit Coupon">
                            <>
                                <i class="bi bi-pencil-square me-2" style={{ fontSize: "1.5rem", color: "#6240C9", cursor: "pointer" }} onClick={() => onEditYearly(record)}></i>
                            </>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <>
                                <i class="bi bi-trash" style={{ fontSize: "1.5rem", color: "#6240C9", cursor: "pointer" }} onClick={() => onDeleteYearly(record?._id)}></i>
                            </>
                        </Tooltip>
                    </>)
            },
        }
    ];

    const onCancelMonthly = () => {
        setMonthlyModal(false);
        setUpdateMonthly(false)
        monthlyForm.resetFields()
    }

    const onDeleteMonthly = (id) => {
        deleteCoupon(id)

    }
    const onEditMonthly = (data) => {
        setUpdateMonthly([
            { name: 'coupon_code', value: data.coupon_code },
            { name: 'coupon_desc', value: data.coupon_desc },
            { name: 'sno', value: data.sno },
        ])
        setMonthlyModal(true)
        setCouponId(data._id)
    }

    const createCoupon = (data, type) => {
        const { coupon_code, coupon_desc } = data
        let pathName = getPlanName()
        if (type === 'monthly')
            setMonthlyModal(false)
        else
            setYearlyModal(false)

        setLoading(true)
        let formData = {
            "coupon_code": coupon_code,
            "coupon_desc": coupon_desc,
            "dynamicSubId": pathName,
            "coupon_plan_id": type === 'monthly' ? monthlyPlanId : yearlyPlanId
        }
        let url = 'admin/dynaminSubscription/createAndUpdateCoupon'
        const successCb = (res) => {
            if (type === 'monthly') {
                setUpdateMonthly(false)
                monthlyForm.resetFields()

            } else {
                setUpdateYearly(false)
                yearlyForm.resetFields()
            }
            setLoading(false)
            getDynamicPlanData()
            message.success("Data Updated Successfully")

        }
        const failureCb = (res) => {
            AppUtils.showMessage("res ", res)
            setLoading(false)

            if (type === 'monthly') {
                setUpdateMonthly(false)
                monthlyForm.resetFields()

            } else {
                setUpdateYearly(false)
                yearlyForm.resetFields()
            }
            let messageData = res?.data?.message ?? ""
            message.error(messageData)
        }
        // return false;
        postCall(formData, url, successCb, failureCb)
    }

    const updateCoupon = (data) => {
        const { coupon_code, coupon_desc } = data
        let pathName = getPlanName()
        setMonthlyModal(false)
        setLoading(true)
        let formData = {
            "coupon_code": coupon_code,
            "coupon_desc": coupon_desc,
            "dynamicSubId": pathName,
            "coupon_id": couponId
        }
        let url = 'admin/dynaminSubscription/createAndUpdateCoupon'
        const successCb = (res) => {

            setUpdateMonthly(false)
            setLoading(false)
            getDynamicPlanData()
            message.success("Data Updated Successfully")
            monthlyForm.resetFields()
        }
        const failureCb = (res) => {
            AppUtils.showMessage("res ", res)
            setLoading(false)
            setUpdateMonthly(false)
            let messageData = res?.data?.message ?? ""
            message.error(messageData)
        }
        // return false;
        postCall(formData, url, successCb, failureCb)
    }

    const deleteCoupon = (id) => {

        setMonthlyModal(false)
        setLoading(true)
        let formData = {

            "coupon_id": id
        }
        let url = 'admin/dynaminSubscription/deleteCoupon'
        const successCb = (res) => {

            setUpdateMonthly(false)
            setLoading(false)
            getDynamicPlanData()
            message.success("Coupon Delete Successfully")

        }
        const failureCb = (res) => {
            AppUtils.showMessage("res ", res)
            setLoading(false)
            setUpdateMonthly(false)
            let messageData = res?.data?.message ?? ""
            message.error(messageData)
        }
        // return false;
        postCall(formData, url, successCb, failureCb)
    }

    const onMonthlySubmitForm = async (data) => {

        if (!updateMonthly) {
            // create new coupon here
            // coupons_monthly.push({ sno:coupons_monthly.length+1,coupon_code,coupon_desc })
            createCoupon(data, 'monthly')
        } else {

            updateCoupon(data)
        }
    }

    const onYearlySubmitForm = async (data) => {

        if (!updateYearly) {
            // create new coupon here
            // coupons_monthly.push({ sno:coupons_monthly.length+1,coupon_code,coupon_desc })
            createCoupon(data, 'yearly')
        } else {

            updateCoupon(data)
        }
    }

    const onCancelYearly = () => {
        setYearlyModal(false);
        setUpdateYearly(false)
        yearlyForm.resetFields()
    }

    const onDeleteYearly = (id) => {
        deleteCoupon(id)
    }
    const onEditYearly = (data) => {
        setUpdateYearly([
            { name: 'coupon_code', value: data.coupon_code },
            { name: 'coupon_desc', value: data.coupon_desc },
            { name: 'sno', value: data.sno },
        ])
        setYearlyModal(true)
        setCouponId(data._id)
    }


    const handleGracePeriod = (value) => {
        console.log(`selected ${value}`);
    };



    // const getDynamicPlanData=()=>{

    // }

    const processData = (data, coupons) => {

        let monthly_plan_id = data.monthly.android_plan_id
        let yearly_plan_id = data.yearly.android_plan_id
        let monthly_coupons_data = coupons.filter((ite) => ite.coupon_plan_id === monthly_plan_id)
        let yearly_coupons_data = coupons.filter((ite) => ite.coupon_plan_id === yearly_plan_id)
        let dynamicfeatures = data?.dynamicfeatures ?? []

        setCouponsMonthly([...monthly_coupons_data])
        setCouponsYearly([...yearly_coupons_data])
        let fields = [
            {
                name: ["_id"],
                value: data?._id,
            },
            {
                name: ["plan_name"],
                value: data?.plan_name,
            },
            {
                name: ["desc"],
                value: data?.desc,
            },
            {
                name: ["android_plan_id_monthly"],
                value: data?.monthly?.android_plan_id,
            },
            {
                name: ["ios_plan_id_monthly"],
                value: data?.monthly?.ios_plan_id,
            },
            {
                name: ["grace_period_monthly"],
                value: data?.monthly?.grace_period
            },
            {
                name: ["grace_period_unit_monthly"],
                value: data?.monthly?.grace_period_unit,
            },
            {
                name: ["price_monthly"],
                value: data?.monthly?.price ?? 0,
            },
            {
                name: ["android_plan_id_yearly"],
                value: data?.yearly?.android_plan_id,
            },
            {
                name: ["ios_plan_id_yearly"],
                value: data?.yearly?.ios_plan_id,
            },
            {
                name: ["grace_period_yearly"],
                value: data?.yearly?.grace_period
            },
            {
                name: ["grace_period_unit_yearly"],
                value: data?.yearly?.grace_period_unit,
            },
            {
                name: ["price_yearly"],
                value: data?.yearly?.price ?? 0,
            }
        ]

        // set all features to form values
        if (dynamicfeatures.length > 0) {
            dynamicfeatures.map((ite) => {
                let key = ite.key
                let enabled = ite.enabled
                let quantity = ite.quantity
                let is_quantity = ite.is_quantity
                if (is_quantity) { // check if quantity set to any feature
                    // update switch value here

                    fields.push({
                        name: key,
                        value: enabled === "true"
                    })
                    // update input value here
                    fields.push({
                        name: `${key}_input`,
                        value: quantity
                    })
                } else {
                    // update switch value here

                    fields.push({
                        name: key,
                        value: enabled === "true"
                    })
                }
            })
        }
        AppUtils.showMessage("fields ", fields)
        setUpdateFields([...fields])
        setSubscriptionFeatures([...dynamicfeatures])
    }

    const getDynamicPlanData = async () => {
        setLoading(true)
        let pathName = getPlanName()
        AppUtils.showMessage("plan name ", pathName)
        const successCb = (response) => {
            AppUtils.showMessage("response ", response)
            setLoading(false)
            // setPagination((prevPagination) => ({
            //     ...prevPagination,
            //     total: response?.data?.[0]?.totalResults,
            // }));
            let data = response.data.data
            let coupons = response.data.coupons

            processData(data, coupons)

            // setData(datas)
        }
        const failureCb = (response) => {
            setLoading(false)
            // setData([])

        }
        getDataCall(1, 10, "", `admin/dynaminSubscription/${pathName}`, successCb, failureCb)
    }

    const getPlanName = () => {
        const pathname = window.location.href;
        // Split the pathname by '/' and filter out empty segments
        AppUtils.showMessage("pathName ", pathname)
        const segments = pathname.split('/').filter(segment => segment !== '');
        // Get the last segment
        const lastSegment = segments.length > 0 ? segments[segments.length - 1] : '';
        // AppUtils.showMessage("lastSegment ",lastSegment)
        return lastSegment
    }

    const onUpdateForm = () => {
        form.submit()

    }

    const updateFuncFree = (values) => {

        // console.log("values ", values)
        let planId = getPlanName()
        // alert(planId)
        let quantityValues = subscriptionFeatures.filter((ite) => ite.is_quantity)
        console.log("stringify values ", quantityValues)
        let isVerified = true
        let message = ''
        for(let i=0;i<quantityValues.length;i++)  {
            let it = quantityValues[i]
            if (it.enabled === 'true') {
                if (!it.is_unlimited && (it.quantity === null || it.quantity === 0)) {
                    isVerified = false
                    message = `${it.name} should not be empty`
                    break
                }
            }
        }
        if (!isVerified) {
            toast.error(message)
            return
        }
        updatePlan(values, planId)
    }

    const updatePlan = async (data, plan_id) => {
        const {
            plan_name, desc, android_plan_id_monthly, ios_plan_id_monthly, grace_period_monthly, grace_period_unit_monthly, price_monthly,
            android_plan_id_yearly, ios_plan_id_yearly, grace_period_yearly, grace_period_unit_yearly, price_yearly
        } = data

        let formData = {
            plan_id,
            plan_name,
            desc,
            android_plan_id_monthly,
            ios_plan_id_monthly,
            grace_period_monthly,
            grace_period_unit_monthly,
            price_monthly: parseInt(price_monthly),
            android_plan_id_yearly,
            ios_plan_id_yearly,
            grace_period_yearly,
            grace_period_unit_yearly,
            price_yearly: parseInt(price_yearly),
            features: subscriptionFeatures
        }
        AppUtils.showMessage("formData ", JSON.stringify(formData))
        setLoading(true)
        const successCb = (response) => {
            AppUtils.showMessage("success response ", response)
            setLoading(false)
            message.success(response?.data?.message)
            navigate('/dynamic_subscription')
        }
        const failureCb = (response) => {
            AppUtils.showMessage("failure response ", response)
            setLoading(false)
            message.error(response?.data?.message ?? "Error creating plan")
        }

        await postCall(formData, "admin/dynaminSubscription", successCb, failureCb)
    }

    const onSwitchChange = (checked, index) => {
        console.log(checked, index)
        subscriptionFeatures[index].enabled = "" + checked
        setSubscriptionFeatures([...subscriptionFeatures])
    }
    const onChangeText = (text, index) => {
        console.log(text, index)
        subscriptionFeatures[index].quantity = text
        setSubscriptionFeatures([...subscriptionFeatures])
    }

    const onCheckChange = (checked, index) => {

        subscriptionFeatures[index].is_unlimited = checked
        if (checked) {
            // let fieldName = subscriptionFeatures[index].key+"_input"
            // let inputValue = form.getFieldValue(fieldName)
            // AppUtils.showMessage("inputValue ",inputValue)
            // if(inputValue === ''){
            //     subscriptionFeatures[index].quantity = 0
            //     form.setFieldValue(fieldName,"0")
            // }

        }
        setSubscriptionFeatures([...subscriptionFeatures])
        // console.log("subscriptionFeatures", subscriptionFeatures)
    }

    const onFeatureUpdate = () => {

        setFeatureId("")
    }

    useEffect(() => {
        getDynamicPlanData()


    }, [form])



    const PlanMonthly = <div className=''>

        <Row className='mt-2'>
            <Col md={10} lg={12} xs={24} sm={24}>
                <label className='fw-bold'>Android Plan Id</label>
                <Form.Item name='android_plan_id_monthly' rules={[{ required: true, message: "Android plan should not be empty" }]} >
                    <Input placeholder="Enter android plan id" className='mt-2' type='text' />
                </Form.Item>
            </Col>
            <Col md={10} lg={12} xs={24} sm={24} className='ps-2'>
                <label className='fw-bold'>IOS Plan Id</label>
                <Form.Item name='ios_plan_id_monthly' rules={[{ required: true, message: "IOS plan should not be empty" }]} >
                    <Input placeholder="Enter ios plan id" className='mt-2' type='text' />
                </Form.Item>
            </Col>
        </Row>
        <Row className='mt-2'>
            <Col md={10} lg={12} xs={24} sm={24}>
                <label className='fw-bold'>Grace Period</label>
                <Form.Item name='grace_period_monthly' rules={[{ required: true, message: "Grace period should not be empty" }]}>
                    <Input placeholder="Enter grace period" className='mt-2' type='number' />
                </Form.Item>
            </Col>
            <Col md={10} lg={12} xs={24} sm={24} className='ps-2'>
                <label className='fw-bold'>Grace Period Unit</label>
                <Form.Item name='grace_period_unit_monthly' rules={[{ required: true, message: "Grace period unit should not be empty" }]}>
                    <Select
                        className='mt-2'
                        defaultValue="month"
                        style={{
                            width: "100%",
                        }}
                        onChange={handleGracePeriod}
                        options={gracePeriod}

                    />
                </Form.Item>
            </Col>
        </Row>
        <Row className='mt-2'>

            <Col md={10} lg={12} xs={24} sm={24} className='ps-2'>
                <label className='fw-bold'>Price</label>
                <Form.Item name='price_monthly' rules={[{ required: true, message: "Price should not be empty" }]}>
                    <Input placeholder="Enter price" className='mt-2' type='text' />
                </Form.Item>
            </Col>
        </Row>

        <Row >
            <Divider />
        </Row>
        <Row >
            <Col md={24} lg={24} xs={24} sm={24}>
                <div className="pb-2 text-end">
                    <Button size={'small'} onClick={() => setMonthlyModal(true)} loading={updateBtnLoading} type="ghost" className="button_common" style={{ width: "auto" }}>+ Add Coupon</Button>
                </div>
            </Col>
        </Row>
        <Row >
            <Col md={24} lg={24} xs={24} sm={24} className='ps-2'>
                <Table
                    dataSource={coupons_monthly}
                    columns={columns_monthly}
                    // pagination={{
                    //     ...pagination,
                    //     onChange: handlePageChange,
                    // }}
                    scroll={{ x: "100%" }}
                />
            </Col>
        </Row>

    </div>

    const PlanYearly = <div className=''>
        <Row className='mt-2'>
            <Col md={10} lg={12} xs={24} sm={24}>
                <label className='fw-bold'>Android Plan Id</label>
                <Form.Item name='android_plan_id_yearly' rules={[{ required: true, message: "Android plan should not be empty" }]} >
                    <Input placeholder="Enter android plan id" className='mt-2' type='text' />
                </Form.Item>
            </Col>
            <Col md={10} lg={12} xs={24} sm={24} className='ps-2'>
                <label className='fw-bold'>IOS Plan Id</label>
                <Form.Item name='ios_plan_id_yearly' rules={[{ required: true, message: "IOS plan should not be empty" }]} >
                    <Input placeholder="Enter ios plan id" className='mt-2' type='text' />
                </Form.Item>
            </Col>
        </Row>
        <Row className='mt-2'>
            <Col md={10} lg={12} xs={24} sm={24}>
                <label className='fw-bold'>Grace Period</label>
                <Form.Item name='grace_period_yearly' rules={[{ required: true, message: "Grace period should not be empty" }]}>
                    <Input placeholder="Enter grace period" className='mt-2' type='number' />
                </Form.Item>
            </Col>
            <Col md={10} lg={12} xs={24} sm={24} className='ps-2'>
                <label className='fw-bold'>Grace Period Unit</label>
                <Form.Item name='grace_period_unit_yearly' rules={[{ required: true, message: "Grace period unit should not be empty" }]}>
                    <Select
                        className='mt-2'
                        defaultValue="month"
                        style={{
                            width: "100%",
                        }}
                        onChange={handleGracePeriod}
                        options={gracePeriod}
                    />
                </Form.Item>
            </Col>
        </Row>
        <Row className='mt-2'>

            <Col md={10} lg={12} xs={24} sm={24} className='ps-2'>
                <label className='fw-bold'>Price</label>
                <Form.Item name='price_yearly' rules={[{ required: true, message: "Price should not be empty" }]}>
                    <Input placeholder="Enter price" className='mt-2' type='text' />
                </Form.Item>
            </Col>
        </Row>
        <Row >
            <Divider />
        </Row>
        <Row >
            <Col md={24} lg={24} xs={24} sm={24}>
                <div className="pb-2 text-end">
                    <Button size={'small'} onClick={() => setYearlyModal(true)} loading={updateBtnLoading} type="ghost" className="button_common" style={{ width: "auto" }}>+ Add Coupon</Button>
                </div>
            </Col>
        </Row>
        <Row >
            <Col md={24} lg={24} xs={24} sm={24} className='ps-2'>
                <Table
                    dataSource={coupons_yearly}
                    columns={columns_yearly}
                    // pagination={{
                    //     ...pagination,
                    //     onChange: handlePageChange,
                    // }}
                    scroll={{ x: "100%" }}
                />
            </Col>
        </Row>
    </div>

    const items = [

        {
            key: '1',
            label: `Monthly`,
            children: <div>{PlanMonthly}</div>,
        },
        {
            key: '2',
            label: 'Yearly',
            children: <p>{PlanYearly}</p>,
        }
    ];


    return (
        <>
         <Row className='mt-4 mb-1 justify-content-end'>
              
              <Button className='back d-flex jusitify-content-center align-items-center' onClick={() => handleGoBack(-1)}><CaretLeftOutlined />Back</Button>
            </Row>
        <div className='Maincard p-3'>
            <Form onFinish={updateFuncFree} form={form} fields={updateFields} name='form'>
                <Spin spinning={loading}>
                    <Row>

                        <Col md={12} lg={12} xs={24} sm={24}>
                            <h4 className='fw-bold mt-2'>Update Subscription</h4>
                        </Col>
                        <Col md={12} lg={12} xs={24} sm={24}>
                            <div className="mt-2 text-end">
                                <Button onClick={() => onUpdateForm()} type="ghost" className="button_common" style={{ width: "auto" }}>
                                    <i class="bi bi-pencil-square me-2" style={{ fontSize: "1.1rem", color: "#ffffff" }}></i> Update Plan

                                </Button>
                            </div>
                        </Col>
                    </Row>



                    <Row className='mt-3'>
                        <Col md={12} lg={12} xs={24} sm={24}>
                            <label className='fw-bold'>Plan Name</label>
                            <Form.Item name={'plan_name'} rules={[{ required: true, message: "Plan name should not be empty" }]}>
                                <Input placeholder="Enter plan name" className='mt-2' />
                            </Form.Item>
                        </Col>
                        <Col md={12} lg={12} xs={24} sm={24}>
                            <label className='fw-bold'>Description</label>
                            <Form.Item name={'desc'} rules={[{ required: true, message: "Plan description should not be empty" }]}>
                                <Input placeholder="Enter plan description" className='mt-2 ms-1' name='desc' />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row className='mt-3'>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <Collapse className='p-2' items={items} defaultActiveKey={['1', '2']} />
                        </Col>
                    </Row>


                    <Row className='mt-2'>
                        <Col md={24} lg={24} xs={24} sm={24}>
                            <label className='fw-bold'>Features</label>
                            <div className='p-3'>
                                {subscriptionFeatures.map((ite, index) => {
                                    return (
                                        <Row className='d-flex align-items-center justify-content-between p-1' key={index + 1}>
                                            <Col xs={16} md={16} lg={16} className='d-flex flex-direction-row' style={{ fontSize: "large" }} >

                                                {/* <Tooltip title="Edit Feature" >
                                                    <div onClick={() => {setFeatureId(ite._id);setFeatureDesc(ite.name);console.log("desc ",ite.name)}}>
                                                        <i class="bi bi-pencil-square me-2" style={{ fontSize: "1rem", color: "#6240C9", cursor: "pointer" }} ></i>
                                                    </div>
                                                </Tooltip> */}
                                                <label>{ite.name}</label>

                                            </Col>
                                            <Col xs={8} md={8} lg={8} className='d-flex align-items-center ps-3'>
                                                {ite.is_quantity ? (
                                                    <div className='d-flex flex-direction-row'>
                                                        <Form.Item name={ite.key} >
                                                            <Switch
                                                                checkedChildren={<CheckOutlined />}
                                                                unCheckedChildren={<CloseOutlined />}
                                                                onChange={(checked) => onSwitchChange(checked, index)}
                                                            />

                                                        </Form.Item>
                                                        {ite.enabled === 'true' && (
                                                            <>
                                                                <Form.Item name={`${ite.key}_input`} className='ms-2' >

                                                                    <Input type='number' placeholder='Enter value' onChange={(e) => onChangeText(e.target.value, index)} disabled={ite.is_unlimited} />

                                                                </Form.Item>
                                                                <Form.Item name={`${ite.key}_checkbox`} className='ms-2'>

                                                                    <Checkbox defaultChecked={ite.is_unlimited} onChange={(e) => onCheckChange(e.target.checked, index)} disabled={parseInt(ite.quantity) > 0}>Unlimited</Checkbox>

                                                                </Form.Item>
                                                            </>
                                                        )}

                                                    </div>
                                                ) : (
                                                    <Form.Item name={ite.key} >
                                                        <Switch
                                                            checkedChildren={<CheckOutlined />}
                                                            unCheckedChildren={<CloseOutlined />}
                                                            onChange={(checked) => onSwitchChange(checked, index)}
                                                        />

                                                    </Form.Item>
                                                )}
                                            </Col>
                                        </Row>
                                    )
                                })}

                            </div>
                        </Col>
                    </Row>
                    <Modal
                        title={updateMonthly ? "Update Coupon" : "Add New Coupon"}
                        centered
                        open={monthlyModal}

                        onCancel={() => onCancelMonthly()}
                        footer={null}
                        width={400}
                    >
                        <Form form={monthlyForm} onFinish={(data) => onMonthlySubmitForm(data)} fields={updateMonthly}>
                            <Form.Item name="coupon_code"

                                // initialValue={updateMonthly?.coupon_code ?? ""}
                                rules={[{ required: true, message: `Please enter coupon code` }]} label="Coupon Code" labelCol={{ span: 24 }}>
                                <Input placeholder="Coupon Code" />
                            </Form.Item>
                            <Form.Item name="coupon_desc"

                                // initialValue={updateMonthly?.coupon_desc??""}
                                rules={[{ required: true, message: `Please enter coupon description` }]} label="Coupon Description" labelCol={{ span: 24 }}>
                                <Input placeholder="Coupon Description" />
                            </Form.Item>

                            <Form.Item className='mx-auto mt-2'>
                                <Button type="primary" className='me-2 w-48' style={{ backgroundColor: "#6240C9" }} htmlType='submit'>
                                    {!updateMonthly ? "Add" : "Update"}

                                </Button>
                                <Button danger type="primary" className='w-48 ' onClick={() => onCancelMonthly()}>Cancel</Button>
                            </Form.Item>
                        </Form>
                    </Modal>

                    <Modal
                        title={updateYearly ? "Update Coupon" : "Add New Coupon"}
                        centered
                        open={yearlyModal}

                        onCancel={() => onCancelYearly()}
                        footer={null}
                        width={400}
                    >
                        <Form form={yearlyForm} onFinish={(data) => onYearlySubmitForm(data)} fields={updateYearly}>
                            <Form.Item name="coupon_code"

                                // initialValue={updateMonthly?.coupon_code ?? ""}
                                rules={[{ required: true, message: `Please enter coupon code` }]} label="Coupon Code" labelCol={{ span: 24 }}>
                                <Input placeholder="Coupon Code" />
                            </Form.Item>
                            <Form.Item name="coupon_desc"

                                // initialValue={updateMonthly?.coupon_desc??""}
                                rules={[{ required: true, message: `Please enter coupon description` }]} label="Coupon Description" labelCol={{ span: 24 }}>
                                <Input placeholder="Coupon Description" />
                            </Form.Item>

                            <Form.Item className='mx-auto mt-2'>
                                <Button type="primary" className='me-2 w-48' style={{ backgroundColor: "#6240C9" }} htmlType='submit'>
                                    {!updateYearly ? "Add" : "Update"}

                                </Button>
                                <Button danger type="primary" className='w-48 ' onClick={() => onCancelYearly()}>Cancel</Button>
                            </Form.Item>
                        </Form>
                    </Modal>

                    <Modal
                        centered
                        open={featureId !== ""}
                        onCancel={() => { setFeatureId(""); setFeatureDesc(""); }}
                        footer={null}
                        width={500}
                    >

                        <h5 className='text-center'>Feature</h5>
                        <Input
                            placeholder="Enter feature description"
                            className='mt-2' type='text'
                            onChange={(e) => setFeatureDesc(e.target.value)}
                            defaultValue={featureDesc}
                            value={featureDesc}
                        />
                        <div className='d-flex flex-direction-row justify-content-end mt-2'>
                            <div>
                                <Button danger type="primary" className='w-20' onClick={() => { setFeatureId(""); setFeatureDesc(""); }}>Cancel</Button>
                            </div>
                            <div style={{ marginLeft: 5 }}>
                                <Button type="primary" className='w-20 ' onClick={() => onFeatureUpdate()}>Update</Button>
                            </div>
                        </div>
                    </Modal>
                </Spin>
            </Form>
        </div>
        <Row justify="end" className='mt-2'>
                <Col md={24} xs={24} sm={24} lg={24} className="d-flex justify-content-center" >
                <Button className='back d-flex jusitify-content-center align-items-center' onClick={() => handleGoBack(-1)}><CaretLeftOutlined />Back</Button>
                </Col>
            </Row>
        </>
    )
}

export default DynamicSubscriptionEdit