const AppUtils = {
  getHeader: () => {
    let header = {
      Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      "x-csrf-token": `${localStorage.getItem("csrf_token")}`
    }
    return header
  },
  showMessage: (key, message) => {
    if (typeof message === 'undefined') {
      console.log(key)
    }
    else {
      console.log(key, message)
    }

  },
  capitalizeFirstLetter: (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  convertSeconds:(seconds)=> {
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    let remainingSeconds = seconds % 60;
    if(hours<10) {
      hours = "0"+hours
    }
    if(minutes<10) {
      minutes = "0"+minutes
    }
    // const duration = moment.duration(seconds, 'seconds');
    // const formatted = duration.format('h [hours], m [minutes], s [seconds]');
    // return formatted;
    return { hours, minutes, seconds: remainingSeconds };
  }
}

export default AppUtils