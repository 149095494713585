import { Button, Col, Form, Modal, Row, message, Spin, Empty } from 'antd'
import React, { useState, useEffect } from 'react'
import ModalQuestion from '../components/ModalQuestion';
import logoDel from '../assets/images/delete.png'
import { Pagination } from 'antd';
import { getDataCallResult, postCall } from '../components/CommonApiCall';
import Search from 'antd/es/input/Search';

function Faq() {
  const [openAddModal, setOpenAddModal] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const [openDelModal, setOpenDelModal] = useState(false)
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false)
  const [faq, setFaq] = useState([])
  const [editData, setEditData] = useState()
  const [paginationData, setPaginationData] = useState()
  const [delId, setDelId] = useState('')

  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(1)


  const handlePagination = (i, j) => {
    setPage(i)
    getData(i, "6")
  }


  // const itemRender = (_, type, originalElement) => {
  //   if (type === 'prev') {
  //     return <a>Previous</a>;
  //   }
  //   if (type === 'next') {
  //     return <a>Next</a>;
  //   }
  //   return originalElement;
  // };
  const [form] = Form.useForm();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 6,
    total: 0,
    position: ['bottomRight'],
    size: 'small'
  });

  /* call to api to get the Users Data */
  useEffect(() => {
    getData();
  }, [pagination.current, searchText])


  //** Called to fetch the users data record **//
  const getData = async (page = 1, limit = '6') => {
    setLoading(true)
    const successCb = (response) => {
      setLoading(false)
      console.log("res total", response?.data?.totalResults)
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: response?.data?.totalResults,
      }));
      setTotal(response?.data?.totalPages)
      let data = response?.data?.results?.map((item) => {
        return ({
          _id: item?._id,
          ques: item?.ques,
          ans: item?.ans,
        })
      })
      setPaginationData(response)
      setFaq(data)
    }
    const failureCb = (response) => {
      setLoading(false)
      setPagination({
        current: 1,
        total: 0,
        pageSize: 6,
      });
    }
    getDataCallResult(page, limit, searchText, "/settings/get_faq", successCb, failureCb);
  }
console.log("dataaaaaaaaaa0",paginationData);
  //*** to handle the Search Value ***//
  const handleSearch = (value) => {
    setSearchText(value);
    setPagination((prevPagination) => ({
      ...prevPagination,
      current: 1,
    }));
  };

  const handleAddQuestion = (values) => {
    setLoading(true)
    let data = {
      ques: values?.Title,
      ans: values?.Message
    }
    console.log('values ',values)
    let url = '/settings/add_faq'
    const successCb = (res) => {
      setLoading(false)
      getData()
      message.success("Data Updated Successfully")
      form.resetFields()
      setOpenAddModal(false)
    }
    const failureCb = (res) => {
      setLoading(false)
    }
    postCall(data, url, successCb, failureCb)
  };

  const handleEditQuestion = (values) => {
    let data = {
      _id: editData?._id,
      ques: values?.Title,
      ans: values?.Message

    }
    let url = '/settings/edit_faq'
    const successCb = (res) => {
      setLoading(false)
      form.resetFields()
      setOpenEditModal(false)
      getData()
      message.success("Data Updated Successfully")
    }
    const failureCb = (res) => {
      setLoading(false)
    }
    postCall(data, url, successCb, failureCb)
  }
  const handleEditButton = (record) => {
    let data = {
      _id: record?._id,
      title: record?.ques,
      message: record?.ans
    }
    setEditData(data)
    setOpenEditModal(true);
  }

  const onPageChange = (pageNumber) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current: pageNumber,
    }));
  };

  const handleDelete = (id) => {
    setDelId(id)
    setOpenDelModal(true)
  }
  const onDeleteOk = () => {
    setLoading(true)
    let data = {
      faqId: delId,
    }
    // return false
    let url = '/settings/delete_faq'
    const successCb = (res) => {
      setLoading(false)
      getData()
      message.success("Question Deleted Successfully")
      form.resetFields()
      setDelId(null)
      setOpenDelModal(false)
    }
    const failureCb = (res) => {
      setLoading(false)
    }
    postCall(data, url, successCb, failureCb)
  }


  return (
    <div className="Maincard p-3">
      <div className='d-flex justify-content-between'>
        <h4 className='p-2 fw-bold'>FAQ</h4>
        {loading && <Spin size="large" className='loader' />}
        <span>
          <Button type='primary' className='purpleButton' onClick={() => setOpenAddModal(true)}>Add Now</Button>
        </span>

      </div>
      <Row className=' justify-content-end'>
        <Search
          placeholder="Search here.."
          onSearch={handleSearch}
          enterButton
          allowClear
          style={{ width: "30%", float: "right", marginBottom: "10px" }}
        />
      </Row>
      <Row className='p-3 d-flex'>
        {faq.length > 0 ? faq?.map((record, i) => {
          return (
            <Col xs={24} sm={24} md={11} lg={11} className='p-3 mb-2 Maincard'>
              <Row className='fw-bold'><Col span={2} >Q{((pagination.current - 1) * 6) + i + 1} </Col><Col> {record?.ques}</Col></Row>
              <Row><Col span={2} className='fw-bold'>Ans. </Col><Col span={22}> {record?.ans}</Col></Row>
              <Row className='my-2'>
                <Col span={2}> </Col>
                <Col span={22}>
                  <Button type="primary" className='px-3 purpleButton' onClick={() => handleEditButton(record)}>Edit</Button>
                  <Button type="primary" danger className='ms-2 px-lg-3' onClick={() => handleDelete(record?._id)}>Delete</Button>
                </Col>
              </Row>
            </Col>
          )
        })
          :
          <Empty />
        }
      </Row>
      <Row className='justify-content-center'>
        {console.log("total pagination", pagination, pagination?.total)}
        {pagination?.total &&
          <>

            {paginationData?.data?.totalResults > 6 && (
              <div className="d-flex justify-content-end">
                <Pagination defaultPageSize={1} total={total} 
                // itemRender={itemRender}
                 onChange={(i, j) => handlePagination(i, j)} className="mt-3" />
              </div>
            )}
          </>
        }
      </Row>
      {openAddModal && <ModalQuestion
        form={form}
        open={openAddModal}
        title="Add Question"
        type="Save"
        onCancel={() => { form.resetFields(); setOpenAddModal(false); }}
        onFinish={handleAddQuestion}
      />
      }
      {openEditModal && <ModalQuestion
        form={form}
        open={openEditModal}
        title="Edit Question"
        data={editData}
        type="Update"
        onCancel={() => { form.resetFields(); setEditData(''); setOpenEditModal(false); }}
        onFinish={handleEditQuestion}
      />
      }
      <Modal
        centered
        open={openDelModal}
        onCancel={() => setOpenDelModal(false)}
        footer={null}
        width={350}
      >
        <div className='text-center'>
          <img src={logoDel} alt="delete_icon" style={{ width: "25%" }} />
        </div>
        <h5 className='text-center'>Delete Question</h5>
        <p>Are you sure you want ot Delete this Question?</p>
        <div>
          <Button type="primary" className='me-2 w-48' style={{ backgroundColor: "#6240C9" }} onClick={onDeleteOk} >Delete</Button>
          <Button danger type="primary" className='w-48 ' onClick={() => { setOpenDelModal(false); setDelId('') }}>Cancel</Button>
        </div>
      </Modal>
    </div>
  )
}

export default Faq