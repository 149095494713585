import { Button, Form, Row, Table, message, Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import ModalQuestion from '../components/ModalQuestion';
import { getDataCallResult, postCall } from '../components/CommonApiCall';
import Search from 'antd/es/input/Search';


function ContactUs() {
  const [searchText, setSearchText] = useState('');
  const [form] = Form.useForm();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false)
  const [contactId, setContactId] = useState(false)

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    position: ['bottomCenter'],
    size: 'small'
  });
  const [openRplyModal, setOpenRplyModal] = useState();

  useEffect(() => {
    getData();
  }, [pagination?.current, pagination?.pageSize, searchText])

  //** Called to fetch the users data record **//
  const getData = async () => {
    setLoading(true)
    const successCb = (response) => {
      setLoading(false)
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: response?.data?.other?.total_entries,
      }));
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: response?.data?.[0]?.totalResults,
      }));
      let datas = response?.data?.[0]?.results?.map((item) => {
        return ({
          _id: item?._id,
          key: item?._id,
          name: item?.name,
          email: item?.email,
          message: item?.message,
        })
      })
      setData(datas)
    }
    const failureCb = (response) => {
      setLoading(false)
      setData('')
      setPagination({
        current: 1,
        total: 0,
        pageSize: 5,
      });
    }
    getDataCallResult(pagination?.current, pagination?.pageSize, searchText, "/settings/contact_us_list", successCb, failureCb)
  }

  const handleReply = (values) => {
    setLoading(true)
    console.log('values ',values)
    let data = {
      title: values?.Title,
      desc: values?.Message,
      contact: contactId
    }
    let url = '/settings/contact_us/addReply'
    const successCb = (res) => {
      setLoading(false)
      form.resetFields()
      getData()
      setOpenRplyModal(false)
      message.success("Reply sent successfully")
    }
    const failureCb = (res) => {
      setLoading(false)
      setOpenRplyModal(false)
      // let res = res?.data
      message.error("Reply can not be sent!!!")
    }
    postCall(data, url, successCb, failureCb)
  }


  const onClickReply = (record) => {
    setOpenRplyModal(true)
    setContactId(record?._id ?? "")
  }

  // columns for the table //
  const columns = [
    {
      title: 'Title',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
    },
    {
      title: 'Action',
      dataIndex: 'Action',
      key: 'Action',
      render: (_, record) => {
        return (
          <>
            <Button className='purpleButton px-4' onClick={() => onClickReply(record)}>Reply</Button>
          </>)
      },
    },
  ];

  // Triggered on Change Page Number
  const handlePageChange = (page, pageSize) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current: page,
      pageSize: pageSize,
    }));
  };

  //*** to handle the Search Value ***//
  const handleSearch = (value) => {
    setSearchText(value);
    setPagination((prevPagination) => ({
      ...prevPagination,
      current: 1,
    }));
  };

  return (
    <div>
      {loading && <Spin size="large" className='loader' />}
      <Row className='justify-content-between'>
        <h4 className='fw-bold mt-4 my-3'>Users Manager</h4>
        <Search
          placeholder="Search by name.."
          onSearch={handleSearch}
          enterButton
          allowClear
          style={{ width: "30%", float: "right", marginBottom: "10px" }}
        />
      </Row>
      <Table
        dataSource={data}
        columns={columns}
        scroll={{ x: "250px" }}
        pagination={{
          ...pagination,
          onChange: handlePageChange,
        }} />
      {openRplyModal && <ModalQuestion
        form={form}
        open={openRplyModal}
        title="Reply"
        type="Reply"
        onCancel={() => setOpenRplyModal(false)}
        onFinish={handleReply}
      />}
    </div>
  )
}

export default ContactUs