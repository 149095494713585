import { Button, Input, Row, Col, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { mainAction } from "../redux/action/mainAction";
import { toast } from "react-toastify";
import { getDataCall, postCall } from "../components/CommonApiCall";
import Select from 'react-select'
import AutocompleteWithoutMap from "../components/AutoCompleteWithoutMap";

const Settings = () => {
    const dispatch = useDispatch()
    const [tipValue, setTipValue] = useState()
    const [subscriptionValue, setSubscriptionValue] = useState()
    const [updateBtnLoading, setUpdateBtnLoading] = useState(false)
    const [toastFalse, setToastFalse] = useState(false)
    const [videoCount, setVideoCount] = useState(0)
    const [loading,setLoading] = useState(false)
    const [apiValues, setApiValues] = useState({
        tip: '',
        sub_watch: ""
    })
    const [id, setId] = useState('')
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        position: ['bottomCenter'],
        size: 'small'
    });
    const [cats, setCats] = useState([]);
    const [selectedCats, setSelectedCats] = useState([]);
    const [location,setLocation] = useState({
        latitude:0.0,
        longitude:0.0,
        address:'NA'
    })

    const [prefs, setPrefs] = useState([]);

    const tipValueHandleChange = (e) => {
        let value = e.target.value
        // setTipValue(value)
        if (value < 0 || value === '0') {
            e.preventDefault();
        } else {
            setTipValue(value);
        }
    }
    const subScrptionOnChange = (e) => {
        let value = e.target.value
        if (value < 0 || value === '0') {
            e.preventDefault();
        } else {
            setSubscriptionValue(value);
        }
    }
    const getTipValue = () => {
        const successCb = (res) => {
            setTipValue(res?.data?.tip_withdrawal_value)
            setSubscriptionValue(res?.data?.free_watch_hours)
            setId(res?.data?._id)
            setApiValues({
                tip: res?.data?.tip_withdrawal_value,
                sub_watch: res?.data?.free_watch_hours
            })
        }
        const failureCb = (res) => {
            console.log("tipValue,fail", res);
        }
        dispatch(mainAction(`settings/get_about_app`, 'get', '', successCb, failureCb, true, false, ''))
    }

    const updateFunc = () => {
        if (tipValue && subscriptionValue) {
            if (apiValues.sub_watch != subscriptionValue || apiValues?.tip != tipValue) {
                let monthHours = 24 * 31
                if (subscriptionValue <= monthHours) {
                    setUpdateBtnLoading(true)
                    let payload = {
                        type: "setting",
                        tip_withdrawal_value: tipValue?.toString(),
                        free_watch_hours: subscriptionValue?.toString(),
                        content: "--",
                        _id: id,
                    }
                    const successCb = (res) => {
                        setToastFalse(false)
                        setUpdateBtnLoading(false)
                        getTipValue()
                        toast.success(res?.message)
                    }
                    const failureCb = (res) => {
                        setUpdateBtnLoading(false)
                        console.log("tipValue,fail", res);
                    }
                    dispatch(mainAction(`settings/update_about_app`, 'post', payload, successCb, failureCb, true, false, ''))
                } else {
                    setToastFalse(true)
                    if (!toastFalse) {
                        toast.error('Hours exceed')
                    }
                    setTimeout(() => {
                        if (!toastFalse) {
                            setToastFalse(false)
                        }
                    }, 5500);
                }
            }
        } else {
            toast.error("Please enter valid value")
        }
    }

    const onSavePreference=()=>{
        if(videoCount === 0) {
            toast.error('Video count should not be empty!!!')
            return
        }
        if(selectedCats.length === 0) {
            toast.error('Categories should not be empty!!!')
            return
        }
        let data = {
            no_videos:String(videoCount),
            categories:selectedCats.map((ite)=>ite.value),
            latitude:location.latitude,
            longitude:location.longitude,
            address:location.address
        }
        setLoading(true)
        const successCb = (response) => {
            setLoading(false)
            // setPagination((prevPagination) => ({
            //     ...prevPagination,
            //     total: response?.data?.[0]?.totalResults,
            // }));
            console.log("response ===> ", response)
            let responseData = response.data
            let message = responseData?.message??''
            // console.log('message ',message)
            toast.success(message)
            
        }
        const failureCb = (response) => {
            setLoading(false)
            // setCats([])
            console.log("failure ===> ", response)
            let responseData = response.data
            toast.error(responseData?.message??"Not able to update")
        }
        postCall(data, "/videoPref/addUpdateVideoPref", successCb, failureCb)
    }

    const getData = async () => {
        setLoading(true)
        const successCb = (response) => {
            setLoading(false)
            // setPagination((prevPagination) => ({
            //     ...prevPagination,
            //     total: response?.data?.[0]?.totalResults,
            // }));
            let datas = response?.data.map((item) => {
                return ({
                    value: ""+item?._id,
                    label: item?.categoryName,
                    status: item?.status
                })
            });
            console.log("datas ",datas)
            setCats([...datas])
            getPreferenceData(datas)
        }
        const failureCb = (response) => {
            setLoading(false)
            setCats([])
            setPagination({
                current: 1,
                total: 0,
                pageSize: 5,
            });
        }
        getDataCall(pagination?.current, pagination?.pageSize, "", "/workouts/list", successCb, failureCb)
    }

    const getPreferenceData = async (cats) => {
        setLoading(true)
        const successCb = (response) => {
            setLoading(false)
            // setPagination((prevPagination) => ({
            //     ...prevPagination,
            //     total: response?.data?.[0]?.totalResults,
            // }));
            console.log("response ===> ", response)
            let responseData = response?.data?.data
            let no_videos = responseData?.no_videos ?? 0
            let categories = responseData?.categories ?? []
            let address = responseData?.address ?? ''
            setVideoCount(no_videos)
            // setSelectedCats([...categories])
            const initialSelectedOptions = cats.filter(option => 
                categories.includes(option.value)
              );
            setSelectedCats([...initialSelectedOptions])  
            location.address = address
            setLocation({...location})
            console.log('address ',address)
            
        }
        const failureCb = (response) => {
            setLoading(false)
            // setCats([])
            console.log("failure ===> ", response)
        }
        postCall({}, "/videoPref/getVideoPref", successCb, failureCb)
    }

    useEffect(() => {
        getTipValue()
        getData()
        
    }, [])
    console.log("tipValue", tipValue);


    const onCatChange = (value) => {
        console.log(value)
        setSelectedCats([...value])
    }
    console.log('location ',location)
    return (
        <Spin spinning={loading}>
        <div className="">
            <h5 className='fw-bold my-2'>Setting</h5>
            <div className="mt-4">
                {/* tip value */}
                {/* <div className="outBoxSettingInput px-2">
                    <div className="">
                        <p className="mt-3" style={{ fontSize: '1rem', paddingLeft: 3 }}>1 Tip Value</p>
                    </div>
                    <div className="settingInput text-end">
                        <Input prefix={`$`} type="number" onChange={(e) => tipValueHandleChange(e)} value={tipValue} className="" />
                    </div>
                </div> */}
                {/* subscription */}
                <div className="outBoxSettingInput px-2 mt-4">
                    <div className="">
                        <p className="mt-3" style={{ fontSize: '1rem', paddingLeft: 3 }}>Free Subscription Watching Hours/month</p>
                    </div>
                    <div className="settingInput subscrption text-end">
                        <Input suffix={`Hours`} type="number" onChange={(e) => subScrptionOnChange(e)} value={subscriptionValue} className="" />
                    </div>
                </div>

                <Button loading={updateBtnLoading} type="ghost" className="purpleButton settingUpdateBtn mt-5" onClick={() => updateFunc()}>Update</Button>
            </div>
            {/* <hr />
            <h5 className='fw-bold my-2 mt-3'>Video Preference Setting</h5>

            <Row>

                <Col md={8}>
                    <div className=" px-2 mt-4 d-flex flex-row align-items-center">
                        <div className="">
                            <p className="mt-3" style={{ fontSize: '1rem', paddingLeft: 3 }}>Video Count</p>
                        </div>
                        <div className="ms-2 subscrption text-end">
                            <Input suffix={``} type="number" className="" placeholder="Enter video count" value={videoCount} onChange={(e) => setVideoCount(e.target.value)} />
                        </div>
                    </div>
                </Col>
                <Col md={8}>
                    <div className=" px-2 mt-4 d-flex flex-row align-items-center">
                        <div className="">
                            <p className="mt-3" style={{ fontSize: '1rem', paddingLeft: 3 }}>Category</p>
                        </div>
                        <div className="ms-2 w-100">
                            
                            {cats.length > 0 ? (
                                <Select
                                    onChange={onCatChange}
                                    mode="multiple"
                                    options={cats}
                                    value={selectedCats}
                                    isMulti
                                    
                                />
                            ):(
                                <p className="mt-3">Loading....</p>
                            )}

                        </div>
                    </div>
                </Col>
                <Col md={8}>
                    <div className=" px-2 mt-4 d-flex flex-row align-items-center">
                        <div className="">
                            <p className="mt-3" style={{ fontSize: '1rem', paddingLeft: 3 }}>Location</p>
                           
                        </div>
                        <div className="subscrption ms-2 d-flex align-items-center">
                            {location.address!='NA' ?(
                                <AutocompleteWithoutMap 
                            address={location.address}
                            onLocationChange={(place)=>
                            setLocation({ 
                                latitude:place.geometry.location.lat(),
                                longitude:place.geometry.location.lng(), 
                                address:place.name, 
                            })}/>
                            ):(
                                <p className="mt-3">Loading....</p>
                            )}
                            
                        </div>
                    </div>
                </Col>
            </Row> */}
            {/* <Row>
                <Col md={12}>
                    <Button loading={updateBtnLoading} type="ghost" className="purpleButton settingUpdateBtn mt-5" onClick={() => {  onSavePreference() }}>Save Preference</Button>
                </Col>
            </Row> */}
        </div>
        </Spin>
    )
}

export default Settings